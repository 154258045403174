<template>
  <div class="site-main">
    <HeaderIndex class="site-header" @menuClick="menuClick" />
    <PageIndex ref="pageContainer" class="site-content" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import HeaderIndex from '@/views/news/header/Index.vue'
import PageIndex from '@/views/news/pages/Index.vue'
export default {
  name: 'Home',
  components: {
    HeaderIndex,
    PageIndex,
  },
  data() {
    return {
    }
  },
  created: function () {
    this.$store.commit('auth/init');
    if (!this.user || !this.token) {
      this.$Message.info('登录已过期');
      // this.$router.replace('/loginNew');
      this.$router.replace('/login');
      return;
    }
  },
  computed: {
    ...mapState('auth', ['token', 'user', 'appType', 'customerId']),
  },
  watch: {
  },
  mounted: function () {
  },
  methods: {
    menuClick: function (menu) {
      // console.log('click menu evt', menu)
      this.$refs.pageContainer.showMenu(menu);
    },
  }
}
</script>
<style scoped>
.site-main {
  width: 100%;
  height: 100%;
  /* border: solid 1px red; */
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}
.site-header {
  /* border: solid 1px red; */
  height: 60px;
  flex: none;
}
.site-content {
  /* border: solid 1px blue; */
  height: 660px;
  flex: auto;
  /* width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column; */
  background-repeat: no-repeat;
  background-size: 100%;
}
</style>
<template>
  <div class="tab-container" :style="{backgroundImage:'url('+img.visual.tunnel.bigModuleBg+')'}">
    <div class="tab-header">
      <div :class="['tab-item', tab.code == active.code ? 'tab-select' : '']" v-for="(tab, tidx) in coms" :key="tidx" @click.stop="tabClick(tab)">
        {{ tab.name }}
        <Icon v-if="tab.code != 'Desktop'" type="md-close" class="tab-close" @click.stop="closePage(tab,false)" />
      </div>
    </div>
    <div class="tab-content" >
      <component v-if="page" v-bind:is="page"></component>
    </div>
    <SiteManageTrans v-model="showSiteManageTransModal" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import SiteManageTrans from './site/device/trans/ModalDataMonitor.vue'
export default {
  name: 'Header',
  components: {
    SiteManageTrans,
  },
  data() {
    return {
      funcs: { //所有功能的页面在此定义
        None: () => import(`./common/None.vue`),
        Desktop: () => import(`./visual/desktop/Index.vue`),
        StateViewTunnel: () => import(`./visual/tunnel/data1.vue`),//隧道可视化
        StateViewRoad: () => import(`./visual/lamp/Index.vue`),//路灯可视化
        ConfigPoleInfo: () => import(`./system/pole/Index.vue`),
        ConfigGroup: () => import(`./system/group/Index.vue`),
        ConfigLightGroup: () => import(`./light/group/Index.vue`),
        ConfigLightPlan1: () => import(`./light/plan1/Index.vue`),
        ConfigLightPlan2: () => import(`./light/plan2/Index.vue`),
        ConfigLightInfo: () => import(`./light/lamp/config/Index.vue`),
        ConfigLightInfoWithPole: () => import(`./light/lamp/configWithPole/Index.vue`),
        ConfigLightAlarmType: () => import(`./light/lamp/alarm/Types.vue`),
        ConfigGateway: () => import(`./light/gateway/config/Index.vue`),
        ConfigVideoAccount: () => import(`./video/account/yingshiyun/Index.vue`),
        ConfigVideoInfo: () => import(`./video/device/Index.vue`),
        ConfigLedInfo: () => import(`./led/device/Index.vue`),
        StateLed: () => import(`./led/state/Index.vue`),
        EnergyLightSetting: () => import(`./light/lamp/energy/Setting.vue`),
        EnergyLightLog: () => import(`./light/lamp/energy/LightLog.vue`),
        EnergyLightTotal: () => import(`./light/lamp/energy/LightTotal.vue`),
        EnergyAreaTotal: () => import(`./light/lamp/energy/AreaTotal.vue`),
        UnionLightLuxConfig: () => import(`./light/lamp/union/LuxIndex.vue`),
        StateGateway: () => import(`./light/gateway/state/Index.vue`),
        StateLight: () => import(`./light/lamp/state/Index.vue`),
        StateAlarm: () => import(`./light/lamp/search/AlarmCurrent.vue`),
        HistoryAlarm: () => import(`./light/lamp/search/AlarmHistory.vue`),
        AnalyseHistoryLight: () => import(`./light/lamp/search/LightLog.vue`),
        AnalyseLightOnRateLog: () => import(`./common/Index4.vue`),
        AnalyseLightOnRateTrend: () => import(`./common/Index1.vue`),
        ConfigLuxRule: () => import(`./lux/rule/Index.vue`),
        ConfigLuxInfo: () => import(`./lux/device/Index.vue`),
        StateLux: () => import(`./lux/state/Index.vue`),
        AnalyseHistoryLuxValue: () => import(`./lux/search/luxlog/Index.vue`),
        SystemRole: () => import(`./system/role/Index.vue`),
        SystemUser: () => import(`./system/user/Index.vue`),
        SystemLog: () => import(`./system/log1/Index.vue`),
        SystemSetting: () => import(`./system/setting/Index.vue`),
        SystemCommon: () => import(`./common/None.vue`),
        SiteManageCust: () => import(`./site/cust/cust/Index.vue`),
        SiteManageUser: () => import(`./site/cust/user/Index.vue`),
        HistoryCommandGateway: () => import(`./light/lamp/search/GatewayCommand.vue`),
        HistoryCommandLight: () => import(`./light/lamp/search/LampCommand.vue`),
        SiteManageDevice: () => import(`./site/device/monitor/Index.vue`),
        SiteManageDeviceLog: () => import(`./site/device/trans/Index.vue`),
        SiteManageIccidLog: () => import(`./site/device/iccid/Index.vue`),
        StateLightView: () => import(`./light/lamp/operate/lampConfiguration.vue`),//单灯组态监控
        StateFastControl: () => import(`./light/state/fastControl.vue`),//快捷控制
        GisLight: () => import(`./gis/Index.vue`),//gis
        SiteManageWeixin: () => import(`./site/weixin/app/Index.vue`),
        SiteManageManufactureTest: () => import(`./site/manufacture/test/Index.vue`),
        SiteManageManufactureStore: () => import(`./site/manufacture/store/Index.vue`),
        ConfigLightPlanTime: () => import(`./light/planLt168/Index.vue`),// Lt168计时日表管理 115B
        ConfigLightPlanClock: () => import(`./light/planWeekLt168/Index.vue`),// Lt168准时周表管理  115B
        ConfigLightInfoLt168WithPole: () => import(`./light/lamp/configLt168/Index.vue`),//  Lt168灯控管理(有灯杆)   115B
        StateLightLt168: () => import(`./light/lamp/stateLt168/Index.vue`),//  Lt168单灯状态监控 115B
        SiteManageSoftware: () => import(`./site/software/Index.vue`),//   固件管理
        AnalyseSettingPowerLine: () => import(`./alarm/savePowerLine/config.vue`),// 线路供电异常分析设置 
        AnalysePowerLineLastErrorView: () => import(`./alarm/savePowerLine/PowerLineHistory.vue`),//最新线路供电异常分析结果  
      },
      active: {},
      coms: [],
      page: null,
      showSiteManageTransModal: false,
    }
  },
  created: function () {
  },
  computed: {
    ...mapState("auth", ["token", "user", "appTypes", 'screenfullCode']),
  },
  watch: {
    screenfullCode() {
      if (this.screenfullCode != '') {
        let data = { code: this.screenfullCode }
        this.closePage(data, true)
      }
    }
  },
  mounted: function () {
  },
  methods: {
    showMenu: function (menu) {
      // console.log('show menu', menu)
      if(menu.code == 'SiteManageTrans'){
        this.showSiteManageTransModal = true;
        return;
      }
      if (this.coms.length > 20) {
        this.$Modal.warning({
          title: "温馨提示",
          content: "页面太多会造成浏览器卡顿，请关闭一些页面"
        });
        return;
      }
      let coms = this.coms.filter(p => p.code == menu.code);
      if (coms.length == 0) {
        this.coms.push(menu);
        this.active = menu;
      } else {
        this.active = coms[0];
      }
      this.page = this.funcs[this.active.code] || this.funcs.None;
      // console.log('get page', this.active.code)
    },
    closePage: function (menu,is) {
      console.log('close page', menu)
      for (let i = 0; i < this.coms.length; i++) {
        if (this.coms[i].code == menu.code) {
          this.coms.splice(i, 1);
          if (this.active.code == menu.code && i > 0) {
            this.active = this.coms[i - 1];
            this.page = this.funcs[this.active.code] || this.funcs.None;
          }
        }
      }
      if (is) {
        this.$store.commit('auth/setScreenfullCode', "");
      }
    },
    tabClick: function (menu) {
      // console.log('tab click', menu)
      this.active = menu;
      this.page = this.funcs[this.active.code] || this.funcs.None;
    },
  },
}
</script>
<style scoped>
.tab-container {
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  /* background-color: #CADFF3; */
  padding: 24px; 

}
.tab-header {
  /* border-bottom: solid 1px rgb(135, 159, 224); */
  /* background-color: linear-gradient(to bottom, #dcdcdc, #fff); */
  display: flex;
  /* height: 30px; */
  flex: none;
}
.tab-item {
  padding: 5px 20px;
  cursor: pointer;
  color: #666666;
  margin-right: 5px;
  font-size: 18px;
  min-width: 140px;
  text-align: center;
}
.tab-close {
  margin-right: -6px;
  color: #333;
}
.tab-select {
  border-radius: 8px 8px 0px 0px;
  color: #3379FB;
  background: #F6F6FA;
}

.tab-content {
  height: 400px;
  flex: auto;
  position: relative;
  overflow: hidden;
  background-color: #F6F6FA;
  /* background-color: transparent; */
  border-radius: 0 10px 10px 10px;
  padding: 14px;
}
</style>
<template>
  <Dropdown placement="bottom-end" trigger="click" @on-click="selectMenu">
    <a href="javascript:void(0)" style="display: flex;align-items:center;">
      <!-- <img style="width: 20px; height: 20px; margin-right: 5px" :src="img.pole.asset.level2.env" /> -->
      <span style="text-align: center;word-break: keep-all;color:#333;">{{user.name}}</span>
      <span style="color:#083366; margin: 0 10px"></span>
      <img style="width: 12px; height: 8px; margin-right: 20px" :src="img.headerUserDown" />
    </a>
    <DropdownMenu slot="list">
      <DropdownItem name="switch">{{showLang('system.switch.app')}}</DropdownItem>
      <Dropdown placement="left-start" style="display: block">
        <DropdownItem>
          中文/English
          <Icon type="ios-arrow-forward"></Icon>
        </DropdownItem>
        <DropdownMenu slot="list">
          <DropdownItem name="Chinese">中文</DropdownItem>
          <DropdownItem name="English">English</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <DropdownItem name="modify">{{showLang('com.pswd.modify')}}</DropdownItem>
      <DropdownItem name="novice">{{showLang('system.novice.guidance')}}</DropdownItem>
      <DropdownItem name="logout">{{showLang('system.log.out')}}</DropdownItem>
    </DropdownMenu>
    <ModalSwitchCustomer v-model="showSwitchModal" />
    <ModalModifyPswd v-model="showModifyModal" />
    <ModalNovice v-model="modalNoviceShow" />
  </Dropdown>
</template>
<script>
import Vue from 'vue';
import { mapState, mapGetters } from "vuex";
import { localData } from '@/plugins/cookie'
Vue.prototype.$localData = { localData }
import ModalSwitchCustomer from './ModalSwitchCustomer'
import ModalWhereDevice from '@/views/common/modals/ModalWhereDevice'
// import ModalWhereUser from '@/views/common/modals/ModalWhereUser'
import ModalModifyPswd from './ModalModifyPswd'
import ModalTaskMonitor from '@/views/common/modals/ModalTaskMonitor'

import ModalNovice from '@/views/common/modals/ModalNovice'
export default {
  name: 'HeaderDropDown',
  components: {
    ModalModifyPswd,
    ModalSwitchCustomer,
    ModalNovice,
  },
  props: {
  },
  data() {
    return {
      langActive: 'zh_cn',
      langList: [
        { name: '中文', code: 'zh_cn' },
        { name: 'English', code: 'en_us' },
      ],
      menus: [
        { code: 'c3', name: '查找设备ID', lang: 'com.site.deviceID.find', com: ModalWhereDevice, show: false },
        // { code: 'c9', name: '查找用户', lang: 'auth.user.find', com: ModalWhereUser, show: false },
        { code: 'c8', name: '任务信息', lang: 'system.task.info', com: ModalTaskMonitor, show: false },
      ],
      showTransLogModal: false,
      showSwitchModal: false,
      showModifyModal: false,
      modalNoviceShow: false,
      custs: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('auth', ['user', 'customerId', 'appType', 'appTypes', 'ops']),
  },
  mounted: function () {
    // this.queryCusts();
  },
  methods: {
    // queryCusts: function(){
    //   this.$axios.post(`common/auth/QueryMyCust`, {}).then(res => {
    //     // console.log('my cust result', res)
    //     this.$set(this, 'custs', res.data);
    //   });
    // },
    langAjax(code) {
      localStorage.setItem("lighting_system_lang", code)
      this.$store.commit('auth/setLang', code);
      location.reload();
    },
    selectMenu: async function (name) {
      if (name == 'logout') {
        this.$store.dispatch('auth/logout').then(res => {
          if (res) {
            this.$Message.info(this.showLang('system.login.out'));
            this.$router.push('/login');
            // this.$router.push('/loginNew');
          }
        });
      } else if (name == 'modify') {
        this.showModifyModal = true;
      } else if (name == 'switch') {
        this.showSwitchModal = true;
      } else if (name == 'novice') {
        this.modalNoviceShow = true;
      } else if (name == 'Chinese') {
        this.langAjax('zh');
      } else if (name == 'English') {
        this.langAjax('en');
      }
    },
  }
}
</script>
<style scoped>
</style>
<template>
  <div class="header-container">
    <div class="header-logo">
      <img v-if="config && config.sysLogo && config.sysLogo.length == 32" :src="`${domains.baseURL}sys/setting/FileContent?id=${config.sysLogo}&auth=${token}`" />
      <span>
        {{config.appName || "城市照明智能监控系统"}}
      </span>
    </div>
    <div class="header-menu">
      <template v-for="(item, idx) in modules">
        <div :class="['header-menu-item', active.code == item.code ? 'menu-select' : '']" v-if="item.code=='Desktop'" :key="idx" @click.stop="menuClick(item,$event)">{{ item.name }}</div>
        <div :class="['header-menu-item', active.code == item.code ? 'menu-select' : '']" v-if="item.code!='Desktop'" :key="idx"  @click.stop="menuClick(item,$event)" @mouseenter.stop="menuClick(item,$event)">{{ item.name }}</div>
      </template>
    </div>
    <div class="header-search">
      <Select v-model="selectCode" filterable prefix="ios-search" style="width:200px">
        <Option v-for="item in selectCodeArr" :value="item.code" :key="item.code">{{ item.name }}</Option>
      </Select>
    </div>
    <div class="header-user">
      <img style="width: 34px; height: 34px; margin-right: 8px" :src="img.visual.tunnel.profilePicture" />
      <!-- {{user.name}} -->
      <HeaderDropDown />
    </div>
    <div class="drop-module" v-show="showSubs" @click.stop="pannelClick" ref="dropModule" :style="{'left':positionLeft+'px'}" :key="showSubsIndex">
      <!-- {{ active }} -->
      <!-- :style="{'left':positionLeft+'px'}" -->
      <div class="sub-menu" v-for="(sub, idx) in active.children" :key="idx">
        <div class="sub-menu-item" v-if="sub.isLeaf" @click.stop="subClick(sub)">
          <i class="sub-menu-spot"></i>
          <span :title="sub.remark">{{ sub.name }}</span>
        </div>
        <div class="third-menu" v-else>
          <div class="sub-menu-header">
            <img :src="judgeIcon(sub.name)" alt="" class="sub-menu-icon">
            {{ sub.name }}
          </div>
          <div class="sub-menu-item" v-for="(sitem, sidx) in sub.children" :key="`${idx}-${sidx}`" @click.stop="subClick(sitem)">
            <!-- {{ sitem }} -->
            <i class="sub-menu-spot"></i>
            <span :title="sitem.remark">{{ sitem.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import HeaderDropDown from './user/HeaderDropDown'
export default {
  name: 'Header',
  components: {
    HeaderDropDown,
  },
  data() {
    return {
      modules: [],
      //this.img.newProject.menuIcon.authority
      icon: {
        '数据可视化': this.img.newProject.menuIcon.visualization,
        '配置': this.img.newProject.menuIcon.configure,
        '操作': this.img.newProject.menuIcon.operation,
        '报警': this.img.newProject.menuIcon.police,
        '查询': this.img.newProject.menuIcon.query,
        '能耗': this.img.newProject.menuIcon.energy,
        '联动': this.img.newProject.menuIcon.linkage,
        '照明GIS': this.img.newProject.menuIcon.map,
        '监控': this.img.newProject.menuIcon.monitor,
        '权限管理': this.img.newProject.menuIcon.authority,
        '日志管理': this.img.newProject.menuIcon.log,
        '系统设置': this.img.newProject.menuIcon.system,
        '组织功能': this.img.newProject.menuIcon.organize,
        '设备功能': this.img.newProject.menuIcon.device,
        '微信功能': this.img.newProject.menuIcon.wx,
        '生产管理': this.img.newProject.menuIcon.beltline,
      },
      selectCode: '',
      selectCodeArr: [],
      active: { code: 'None' },
      showSubs: false,
      positionLeft: 500,
      showSubsIndex: 0,
      config: {},
    }
  },
  created: function () {
    // this.active = this.modules[0];
  },
  computed: {
    ...mapState('auth', ['token', 'user', 'appTypes']),
    ...mapGetters('auth', ['getPage'])
  },
  watch: {
    selectCode() {
      if (this.selectCode != '') {
        let menu = this.selectCodeArr.filter(p => p.code == this.selectCode)
        this.$emit('menuClick', menu[0]);
      }
    },
    showSubs() {
      this.showSubsIndex++;
    }
  },
  mounted: function () {
    this.initMenus();
    window.onclick = () => {
      this.showSubs = false;
    }
  },
  methods: {
    judgeIcon(name) {
      let iconSrc = '';
      for (let key in this.icon) {
        if (key === name) {
          return iconSrc = this.icon[key]
        }
      }
      return iconSrc;
    },
    initMenus: function () {
      this.$axios.post(`common/auth/QueryAllMenus`, {}).then(res => {
        if (res.code !== 0) return;
        this.$set(this, 'modules', res.data.menus);
        this.$set(this, 'config', res.data.config);
        this.active = this.modules[0];
        this.$emit('menuClick', this.active);
        // this.$store.commit('auth/setCustConfig', res.data.config);
        this.$store.commit('auth/setCustConfig', { config: res.data.config, operations: res.data.operations });
        this.selectCodeArr = [];
        this.selectData();
      });
    },
    selectData() {
      this.modules.forEach(el => {
        if (el.children != null && !el.isLeaf) {
          el.children.forEach(ele => {
            if (ele.children != null && !el.isLeaf) {
              ele.children.forEach(data => {
                this.selectCodeArr.push(data)
              })
            }
          })
        }
      });
    },
    calculate(el) {
      if (!this.showSubs) { return false; }
      setTimeout(() => {
        let width1 = this.$refs.dropModule.clientWidth;
        //el.offsetX  el.offsetY
        this.positionLeft = el.x - width1 + (width1 / 2)-el.offsetX;
        let exceed = this.positionLeft + width1;
        if (exceed > window.innerWidth) {
          this.positionLeft = this.positionLeft - (exceed - window.innerWidth)
        }
      }, 10);
    },
    menuClick: function (menu, el) {
      let oldMenu = this.active ? this.active.code : '';
      this.showSubsIndex++;
      this.active = menu;
      if (menu.isLeaf) {
        this.$emit('menuClick', menu);
        this.showSubs = false;
      } else {
        if (menu.code == oldMenu) {
          this.showSubs = !this.showSubs;
        } else {
          this.showSubs = true;
        }
      }
      this.calculate(el)
    },
    subClick: function (menu) {
      this.$emit('menuClick', menu);
      this.showSubs = false;
    },
    pannelClick: function () {
    },
  },
}
</script>
<style scoped>
.header-container {
  display: flex;
  background-color: #fff;
}
.header-logo {
  /* border: solid 1px red; */
  flex: none;
  font-size: 20px;
  color: #333333;
  padding-left: 15px;
  line-height: 60px;
  display: flex;
  align-items: center;
}
.header-logo img {
  vertical-align: middle;
  margin: 0 20px 0 9px;
  /* height: 50px; */
  max-width: 135px;
  max-height: 32px;
}
.header-logo span {
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
}
.header-user {
  /* border: solid 1px red; 
  width: 150px;*/
  flex: none;
  margin: auto 9px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
}
.header-menu {
  /* border: solid 1px red; */
  width: 350px;
  flex: auto;
  display: flex;
  justify-content: flex-end;
}
.header-menu-item {
  border-bottom: solid 3px #fff;
  cursor: pointer;
  flex: none;
  padding: 0 22px;
  font-size: 15px;
  color: #333333;
  font-weight: bold;
  line-height: 60px;
  user-select: none;
}
.menu-select {
  color: #3379fb;
  /* border-bottom: solid 3px #3379fb; */
}
.menu-select::after {
  content: "";
  width: 100%;
  height: 1px;
  display: block;
  margin: -3px auto 0;
  border-bottom: 3px solid #3880fc;
}
.drop-module {
  /* border: solid 1px rgba(0, 0, 255, 0.24); */
  position: absolute;
  /* width: 100%; */
  min-height: 100px;
  top: 61px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  z-index: 100;
  padding: 20px 20px 40px;
  box-shadow: 3px 4px 24px 0px rgba(63, 98, 163, 0.2);
  border-radius: 0px 0px 10px 10px;
}
.sub-menu {
  /* border: solid 1px rgba(0, 0, 255, 0.24); */
  /* width: 150px; */
  margin: 20px 35px 0 35px;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  cursor: default;
  /* padding: 0 10px; */
}
.third-menu {
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: default;
}
.sub-menu-header {
  width: 100%;
  cursor: default;
  font-size: 18px;
  font-weight: bold;
  padding-left: 5px;
  color: #333333;
}
.sub-menu-item {
  /* border: solid 1px red; */
  user-select: none;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  padding: 0 10px;
  margin-top: 15px;
  color: #666666;
}
.sub-menu-spot {
  width: 6px;
  height: 6px;
  background: #adadad;
  border-radius: 50%;
  margin-top: 10px;
  margin-right: 20px;
}
.sub-menu-icon {
  vertical-align: middle;
  margin-bottom: 3px;
  margin-right: 10px;
}
.header-search {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
<template>
  <div class="switch-organization" v-if="showModal" @click.stop="stopClick()">
    <div class="organization-box">
      <div class="organization-top">
        <img :src="img.home.switchOrganization.switchLogo" alt="" class="logo">
        <img :src="img.home.switchOrganization.cancel" alt="" class="cancel" @click="cancel()">
      </div>
      <div class="user-list">
        <div class="user-search">
          <Input v-model="searchVal" icon="ios-search-strong" class="user-search-input" :placeholder="showLang('save.keywords')"></Input>
        </div>
        <div class="cust-area">
          <div v-for="(item, idx) in searchData" :key="idx" @click.stop="selectCust(item)" :class="['cust-item', item.id == customerId ? 'cur-item':'']">
            <span :class="['circular', item.id == customerId ? 'circular-active':'']"><i class="spot-active" v-if="item.id == customerId"></i></span>
            {{item.appName}} - {{item.name}}
            <img :src="img.home.switchOrganization.select" alt="" v-if="item.id == customerId" class="select-active">
            <div v-else-if="!item.isOwner && !user.isSuper" class="leave-active" @click.stop="leaveCust(item)">退出项目</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
export default {
  name: 'ModalSwitchCustomer',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    // custs: {
    //   type: Array,
    //   default(){return [];}
    // }
  },
  data() {
    return {
      searchVal: "",
      searchData: [],
      custs: [],
      showModal: this.value,
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('auth', ['token', 'user', 'customerId', 'isNewMode', 'appType']),
    curCust: function () {
      return this.customers.filter(p => p.id == this.customerId)[0];
    },
  },
  watch: {
    custs() {
      this.$set(this, 'searchData', this.custs);
    },
    searchVal() {
      let arr = this.custs.filter(item => item.name.indexOf(this.searchVal) != -1 || item.appName.indexOf(this.searchVal) != -1);
      this.$set(this, 'searchData', arr);
    },
    value(newVal) {
      this.showModal = newVal;
      if (this.showModal) {
        this.queryCusts();
        this.searchVal = "";
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  methods: {
    leaveCust: function(params){
      console.log('leave cust', params, this.user);
      this.$Modal.confirm({
        title: "提示",
        content: `确定要退出项目[${params.appName}]吗?`,
        onOk: async () => {
          this.$axios.post(`site/customer/RemoveUserCust`, { custId: params.id, userId: this.user.id }).then(res => {
            if (res.code == 0) {
              this.queryCusts();
            }
          });
        }
      });
    },
    queryCusts: function(){
      this.$axios.post(`common/auth/QueryMyCust`, {}).then(res => {
        // console.log('my cust result', res)
        this.$set(this, 'custs', res.data.filter(p => p.appType == 'road'));
      });
    },
    selectCust: function (item) {
      // let app = item;
      if (this.customerId == item.id) return;
      let appChange = true;
      this.$store.dispatch('auth/switchAppCustNew', { cust: item.id, app: 'road' }).then(res => {
        if (res.code == 0) {
          this.$Message.info(this.showLang('system.switch.success'));
          this.showModal = false;
          if (appChange) {
            setTimeout(() => {
              // console.log('go router', this.isNewMode, this.appType, this.$router, this.$route)
              let url = '/main';
              if(!this.isNewMode){
                switch(this.appType){
                  case 'road': url = '/index/light/road/home'; break;
                  case 'factory': url = '/index/light/factory/home'; break;
                  case 'pole': url = '/index/pole/home'; break;
                  case 'light': url = '/index/light/single'; break;
                }
              }
              if(url == this.$route.path){
                window.location.reload();
              }else{
                this.$router.replace(url);
              }
              // this.$router.replace('/main');
              // this.$store.commit('group/selectNodeById', {nodeId: 0, type: 'root'})
              // setTimeout(() => {
              //   window.location.reload();
              // }, 300);
            }, 200);
          }
        }
      });
    },
    stopClick() {
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.center-menu {
  /* border: solid 1px red; */
  flex: none;
  height: 200px;
  display: flex;
  justify-content: space-between;
}
.cust-area {
  /* border: solid 1px red; */
  height: 360px;
  flex: auto;
  overflow-y: auto;
}
.menu-item {
  /* border: solid 1px red; */
  cursor: pointer;
  width: 148px;
  flex: none;
  height: 205px;
  color: #ffffff;
  position: relative;
}
.menu-selected {
  /* border: solid 1px red; */
}
.menu-item img {
  position: absolute;
  left: 50%;
  margin-left: -44px;
  top: 40px;
}
input::placeholder {
  color: #45689c;
}
.user-search >>> input::-webkit-input-placeholder {
  color: #45689c;
}
.menu-item-title {
  position: absolute;
  bottom: 13px;
  left: 10px;
  width: 130px;
  height: 35px;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #0591fa;
  background-image: -webkit-linear-gradient(
    bottom,
    #002e50,
    #51b4feff,
    #ffffff
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.switch-organization {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(1, 11, 22, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}
.organization-box {
  width: 982px;
  height: 819px;
  background: linear-gradient(90deg, #001b35, #001b35, #001b35);
  border-radius: 12px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -491px;
  margin-top: -391px;
  display: flex;
  flex-direction: column;
}
.cust-list {
  display: flex;
  flex-direction: column;
  height: 600px;
  overflow: hidden;
}
.organization-top {
  height: 50px;
  flex: none;
}
.organization-top .logo {
  position: absolute;
  left: 50%;
  margin-left: -151px;
  top: 0;
}
.organization-top .cancel {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.user-search {
  margin: 20px auto 20px;
  flex: none;
}
.user-search-input >>> .ivu-input {
  width: 382px;
  height: 44px;
  background: #15335f;
  border-radius: 8px;
  margin: 0 auto;
  text-align: center;
  border: none;
  color: #45689c;
  font-size: 16px;
}
.user-search-input >>> .ivu-input-icon {
  left: 340px;
  top: 5px;
  color: #fff;
}

.user-list {
  /* border: solid 1px red; */
  width: 652px;
  height: 450px;
  flex: auto;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  /* padding-top: 20px; */
  margin-top: 10px;
  margin-bottom: 50px;
}
.cust-item {
  /* border: solid 1px red; */
  width: 100%;
  height: 44px;
  background: #153b6d;
  border-radius: 8px;
  margin-bottom: 18px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 44px;
  cursor: pointer;
  /* display: flex; */
}
.cust-item.cur-item {
  box-sizing: border-box;
  background: linear-gradient(0deg, #153b6d, #20559a);
  border: 1px solid #01cfd0;
}
.circular {
  width: 14px;
  height: 14px;
  border: 1px solid #447bc8;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px 0 20px;
  line-height: 54px;
  vertical-align: middle;
}
.circular.circular-active {
  border: 1px solid #00f6f7;
}
.spot-active {
  width: 8px;
  height: 8px;
  background: #02eef2;
  border: 1px solid #00f6f7;
  border-radius: 50%;
  display: block;
  margin: 2px 0 0 2px;
}
.select-active {
  float: right;
  margin-right: 20px;
  margin-top: 10px;
}
.leave-active {
  float: right;
  margin-right: 20px;
  /* margin-top: 10px; */
}
</style>